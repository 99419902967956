import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { workoutReminderMagicKeys } from "@trainwell/features/workout-reminders";
import AutosaveTextField from "src/components/misc/AutosaveTextField";
import ControlBar from "src/components/misc/ControlBar";
import { SmartEditListMagicKeys } from "src/components/misc/SmartEditListMagicKeys";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { useIsCpt2Trainer } from "src/hooks/useIsCpt2Trainer";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export default function MessageTemplatesPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const theme = useTheme();

  const isCpt2Trainer = useIsCpt2Trainer();

  if (!trainer) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Container maxWidth="md" sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Message templates
        </Typography>
        <Grid
          container
          spacing={4}
          alignItems={"center"}
          maxWidth={600}
          sx={{ mb: 4 }}
        >
          <Grid
            size={{
              xs: 4,
            }}
          >
            <img
              src="/assets/intro-message.jpeg"
              width={"100%"}
              style={{
                borderRadius: 12,
                maxWidth: 250,
                border: `1px solid ${theme.palette.divider}`,
              }}
            />
          </Grid>
          <Grid
            size={{
              xs: 8,
            }}
          >
            <Typography>
              From your first introduction to their one year check in call,
              message templates give you the power to personalize
              trainwell&apos;s messages.
              <br />
              <br />
              Be silly. Be serious. Be slick. Be you 🤪
            </Typography>
          </Grid>
        </Grid>
        <Stack spacing={4}>
          <Card variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h2">Intro message</Typography>
            <Typography sx={{ my: 1.5 }}>
              Give a quick welcome and introduction 👋 then start a conversation
              with your welcome survey questions.
              <br />
              <br />
              We typically recommend 1-3 questions for approachability.
              <br />
              <br />
              Also make sure there&apos;s no header to this message, just start
              with a greeting like you are messaging someone for the first time!
            </Typography>
            <AutosaveTextField
              defaultValue={trainer.message_templates.intro_message ?? ""}
              disableGutter
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "message_templates.intro_message": newValue,
                  }),
                );
              }}
              sanitizeValue={(value) => {
                // Remove non-ascii characters
                // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
                // eslint-disable-next-line no-control-regex
                return value.replace(/[^\x00-\x7F]/g, "");
              }}
              helperText="This messages currently does not support emojis"
            />
          </Card>
          <Card variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h2">Client switched trainer</Typography>
            <Typography sx={{ my: 1.5 }}>
              Automatically sent when a client switches trainers to you 👋. Sent
              both as sms and in the trainwell app. If a client switches to you
              before they&apos;re onboarded, your Intro Message is sent instead.
              <br />
              <br />
              This is often similar to your Intro Message. Introduce yourself
              and ask a few starter questions.
            </Typography>
            <AutosaveTextField
              defaultValue={
                trainer.message_templates.client_switched_trainer ?? ""
              }
              disableGutter
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "message_templates.client_switched_trainer": newValue,
                  }),
                );
              }}
              sanitizeValue={(value) => {
                // Remove non-ascii characters
                // https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
                // eslint-disable-next-line no-control-regex
                return value.replace(/[^\x00-\x7F]/g, "");
              }}
              helperText="This messages currently does not support emojis"
            />
          </Card>
          <Card variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h2">Client returned from cancel</Typography>
            <Typography sx={{ my: 1.5 }}>
              Automatically sent when a client returns from being cancelled
              🎉🎉🎉. This is NOT sent for clients that are active and decide to
              change their mind about cancelling, only for clients that go from
              fully cancelled to active.
              <br />
              <br />
              This is a good moment to ask what brings the client back and set
              expectations for next steps.
            </Typography>
            <AutosaveTextField
              defaultValue={
                trainer.message_templates.client_returned_from_cancel ?? ""
              }
              disableGutter
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "message_templates.client_returned_from_cancel": newValue,
                  }),
                );
              }}
            />
          </Card>
          <Card variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h2">Check in call scheduled</Typography>
            <Typography sx={{ my: 1.5 }}>
              Automatically sent when a client schedules a check in call 🤙.
              Sent in the trainwell app.
              <br />
              <br />
              Ask some questions to give you a head start on the call.
            </Typography>
            <AutosaveTextField
              disableGutter
              defaultValue={trainer.pre_call_survey_checkin}
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    pre_call_survey_checkin: newValue,
                  }),
                );
              }}
            />
          </Card>
          <Card variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h2">Pop-ups</Typography>
            <Typography
              sx={{
                mt: 1.5,
                mb: 3,
              }}
            >
              These messages are sent after you take an action (ex. finishing
              onboarding) and are <b>not</b> sent automatically.
              <br />A popup will let you change the message or choose not to
              send the message.
            </Typography>
            <AutosaveTextField
              label="Finished onboarding call"
              defaultValue={
                trainer.message_templates.client_finished_onboarding_call
              }
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "message_templates.client_finished_onboarding_call":
                      newValue,
                  }),
                );
              }}
            />
            <AutosaveTextField
              label="Missed onboarding call"
              defaultValue={
                trainer.message_templates.client_missed_onboarding_call
              }
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "message_templates.client_missed_onboarding_call": newValue,
                  }),
                );
              }}
            />
            <AutosaveTextField
              label="Check in survey action item"
              helperText="The system will automatically add a link to the check-in survey at the bottom of this message."
              disableGutter
              defaultValue={trainer.message_templates.check_in_survey}
              onSave={(newValue) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "message_templates.check_in_survey": newValue,
                  }),
                );
              }}
            />
          </Card>
          {isCpt2Trainer && (
            <Card variant="outlined" sx={{ p: 3 }}>
              <Typography variant="h2">Morning workout reminders</Typography>
              {(trainer.message_templates.workout_morning_reminders ?? [])
                .length < 10 && (
                <Typography color="error" sx={{ my: 1.5 }}>
                  Please add at least ten messages to enable this feature.
                </Typography>
              )}
              <Typography
                sx={{
                  my: 1.5,
                }}
              >
                This is sent at 8am in the client's timezone if they have a
                workout assigned for that day. It won't be sent if they did a
                workout or if they're in a workout. These should be about a
                sentence.
              </Typography>
              <SmartEditListMagicKeys
                magicKeyOptions={workoutReminderMagicKeys}
                values={
                  trainer.message_templates.workout_morning_reminders ?? []
                }
                name="morning workout reminder"
                previewSuffix={`\n\n[Scheduled]`}
                onSave={(newValues) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "message_templates.workout_morning_reminders": newValues,
                    }),
                  );
                }}
              />
            </Card>
          )}
          {isCpt2Trainer && (
            <Card variant="outlined" sx={{ p: 3 }}>
              <Typography variant="h2">10 minute workout reminders</Typography>
              {(trainer.message_templates.workout_10_minute_reminders ?? [])
                .length < 10 && (
                <Typography color="error" sx={{ my: 1.5 }}>
                  Please add at least ten messages to enable this feature.
                </Typography>
              )}
              <Typography
                sx={{
                  my: 1.5,
                }}
              >
                This is sent 10 minutes before a client's workout is expected to
                be started. It won't be sent if they did a workout or if they're
                in a workout. These should be about a sentence.
              </Typography>
              <SmartEditListMagicKeys
                magicKeyOptions={workoutReminderMagicKeys}
                values={
                  trainer.message_templates.workout_10_minute_reminders ?? []
                }
                name="10 minute workout reminder"
                onSave={(newValues) => {
                  dispatch(
                    updateTrainer({
                      trainer_id: trainer.trainer_id,
                      // @ts-expect-error
                      "message_templates.workout_10_minute_reminders":
                        newValues,
                    }),
                  );
                }}
              />
            </Card>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
