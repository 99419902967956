import AvTimerRoundedIcon from "@mui/icons-material/AvTimerRounded";
import DirectionsRunRoundedIcon from "@mui/icons-material/DirectionsRunRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import FormatListNumberedRoundedIcon from "@mui/icons-material/FormatListNumberedRounded";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import MonitorHeartRoundedIcon from "@mui/icons-material/MonitorHeartRounded";
import PhoneIphoneRoundedIcon from "@mui/icons-material/PhoneIphoneRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import SelfImprovementRoundedIcon from "@mui/icons-material/SelfImprovementRounded";
import SignalWifi3BarRoundedIcon from "@mui/icons-material/SignalWifi3BarRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import WatchRoundedIcon from "@mui/icons-material/WatchRounded";
import WhatshotRoundedIcon from "@mui/icons-material/WhatshotRounded";
import type { SxProps, Theme } from "@mui/material";
import { Box, Card, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { WorkoutLog } from "@trainwell/features/legacy";
import { getConvertedWeight } from "@trainwell/workout-lib";
import LoadingComponent from "src/components/miscPages/LoadingComponent";
import { useAppSelector } from "src/hooks/stateHooks";
import { exerciseMap } from "src/lib/exercises";
import { closeToNumber } from "src/lib/misc";

function formatTime(t: number) {
  const mins = Math.floor(t / 60);
  const secs = Math.floor(t % 60);

  return mins + ":" + String(secs).padStart(2, "0");
}

interface Props {
  log: WorkoutLog;
  sx?: SxProps<Theme>;
  size?: "small" | "medium";
}

export default function LogIconGrid({ log, sx = [], size }: Props) {
  const theme = useTheme();
  const client = useAppSelector((state) => state.client.client);

  if (!log || !exerciseMap) {
    return <LoadingComponent message="Looking for log" />;
  }

  let distance: number | undefined = undefined;
  let pace: number | undefined = undefined;

  if (log.log_summary.cardio_metrics?.distance) {
    distance = log.log_summary.cardio_metrics.distance;
    pace =
      log.log_summary.metrics.duration_performed /
      log.log_summary.cardio_metrics.distance;
  }

  const fontSize =
    size === "small" ? theme.typography.body2.fontSize : undefined;
  const fontSizeIcon = size === "small" ? 20 : undefined;

  return (
    <Card variant="outlined" sx={sx}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: 1,
          py: 0.5,
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        }}
      >
        {log.tech_summary?.watch_used ? (
          <>
            <WatchRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography>Watch used</Typography>
          </>
        ) : (
          <>
            <PhoneIphoneRoundedIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography>Phone only</Typography>
          </>
        )}
      </Box>
      <Grid
        container
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          alignItems: "stretch",
          justifyItems: "stretch",
          borderBottom: 0.5,
          borderColor: "divider",
          ".MuiGrid-item": {
            border: 0.5,
            borderColor: "divider",
            padding: size === "small" ? 0.5 : 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          },
        }}
      >
        <Tooltip title="Estimated duration" disableInteractive>
          <Grid size="grow">
            <TimelapseRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography sx={{ fontSize: fontSize }}>
              {log.log_summary.metrics.duration_target
                ? Math.round(log.log_summary.metrics.duration_target / 60) + "m"
                : "--"}
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title="Actual duration" disableInteractive>
          <Grid size="grow">
            <TimerRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography sx={{ fontSize: fontSize }}>
              {Math.round(log.log_summary.metrics.duration_performed / 60)}m
            </Typography>
          </Grid>
        </Tooltip>

        <Tooltip title="Calories burned" disableInteractive>
          <Grid size="grow">
            <WhatshotRoundedIcon sx={{ fontSize: fontSizeIcon }} />
            <Typography sx={{ fontSize: fontSize }}>
              {log.log_summary.metrics.calories
                ? Math.round(log.log_summary.metrics.calories) + " kCal"
                : "--"}
            </Typography>
          </Grid>
        </Tooltip>
        {log.log_summary && (
          <Tooltip
            title={
              distance
                ? "Distance (mi)"
                : `Volume ${
                    client?.preferred_weight_system === "imperial"
                      ? "lbs"
                      : "kg"
                  }`
            }
            disableInteractive
          >
            <Grid size="grow">
              {distance ? (
                <LocationOnRoundedIcon sx={{ fontSize: fontSizeIcon }} />
              ) : (
                <FitnessCenterRoundedIcon sx={{ fontSize: fontSizeIcon }} />
              )}
              <Typography sx={{ fontSize: fontSize }}>
                {distance
                  ? Math.round(log.log_summary.cardio_metrics!.distance! * 10) /
                      10 +
                    " miles"
                  : getConvertedWeight({
                      weight: log.log_summary.metrics.volume,
                      fromSystem: "imperial",
                      toSystem: client?.preferred_weight_system ?? "imperial",
                      round: true,
                    }) +
                    ` ${
                      client?.preferred_weight_system === "imperial"
                        ? "lbs"
                        : "kg"
                    }`}
              </Typography>
            </Grid>
          </Tooltip>
        )}
        {log.log_summary && (
          <Tooltip
            title={pace ? "Avg pace" : "Reps performed"}
            disableInteractive
          >
            <Grid size="grow">
              {pace ? (
                <AvTimerRoundedIcon sx={{ fontSize: fontSizeIcon }} />
              ) : (
                <ReplayRoundedIcon sx={{ fontSize: fontSizeIcon }} />
              )}
              <Typography sx={{ fontSize: fontSize }}>
                {pace
                  ? formatTime(pace) + " / mile"
                  : log.log_summary.metrics.n_reps + " reps"}
              </Typography>
            </Grid>
          </Tooltip>
        )}
        {log.log_summary && (
          <Tooltip title="Network quality" disableInteractive>
            <Grid size="grow">
              <SignalWifi3BarRoundedIcon
                sx={{
                  fontSize: fontSizeIcon,
                  color: !log.tech_summary?.motion_packet_summary
                    ?.network_quality
                    ? undefined
                    : log.tech_summary?.motion_packet_summary.network_quality >
                        0.95
                      ? "green"
                      : "red",
                }}
              />
              <Typography sx={{ fontSize: fontSize }}>
                {log.tech_summary?.motion_packet_summary?.network_quality
                  ? Math.round(
                      log.tech_summary?.motion_packet_summary.network_quality *
                        100,
                    ) + "%"
                  : "--"}
              </Typography>
            </Grid>
          </Tooltip>
        )}
        <Tooltip title="Completion" disableInteractive>
          <Grid size="grow">
            <FlagRoundedIcon
              sx={{ fontSize: fontSizeIcon }}
              color={
                log.log_summary.metrics.completion < 1 ? "error" : undefined
              }
            />
            <Typography
              sx={{
                fontSize: fontSize,
                color: (theme) =>
                  log.log_summary.metrics.completion < 1
                    ? theme.palette.error.main
                    : undefined,
                fontWeight:
                  log.log_summary.metrics.completion < 1 ? "bold" : undefined,
              }}
            >
              {Math.round(log.log_summary.metrics.completion * 100)}%
            </Typography>
          </Grid>
        </Tooltip>
        <Tooltip title="Workout difficulty" disableInteractive>
          <Grid size="grow">
            <MonitorHeartRoundedIcon
              sx={{ fontSize: fontSizeIcon }}
              color={
                log.log_summary.user_feedback &&
                !closeToNumber(
                  Math.round(log.log_summary.user_feedback.difficulty * 100),
                  50,
                  2,
                )
                  ? "error"
                  : undefined
              }
            />
            <Typography
              sx={{
                fontSize: fontSize,
                fontWeight:
                  log.log_summary.user_feedback &&
                  !closeToNumber(
                    Math.round(log.log_summary.user_feedback.difficulty * 100),
                    50,
                    2,
                  )
                    ? "bold"
                    : undefined,
                color: (theme) =>
                  log.log_summary.user_feedback &&
                  !closeToNumber(
                    Math.round(log.log_summary.user_feedback.difficulty * 100),
                    50,
                    2,
                  )
                    ? theme.palette.error.main
                    : undefined,
              }}
            >
              {log.log_summary.user_feedback
                ? Math.round(log.log_summary.user_feedback.difficulty * 100) +
                  "%"
                : "--"}
            </Typography>
          </Grid>
        </Tooltip>
        {log.log_summary?.quality && (
          <Tooltip
            title={`Set completion: ${log.log_summary.quality.set_completion.details}`}
            disableInteractive
          >
            <Grid size="grow">
              <FormatListNumberedRoundedIcon
                sx={{ fontSize: fontSizeIcon }}
                color={
                  log.log_summary.quality.set_completion.percent < 1
                    ? "error"
                    : undefined
                }
              />
              <Typography
                sx={{
                  fontSize: fontSize,
                  color: (theme) =>
                    log.log_summary.quality.set_completion.percent < 1
                      ? theme.palette.error.main
                      : undefined,
                  fontWeight:
                    log.log_summary.quality.set_completion.percent < 1
                      ? "bold"
                      : undefined,
                }}
              >
                {Math.round(
                  log.log_summary.quality.set_completion.percent * 100,
                )}
                %
              </Typography>
            </Grid>
          </Tooltip>
        )}
        {log.log_summary?.quality && (
          <Tooltip
            title={`Pacing: ${log.log_summary.quality.pacing?.details}`}
            disableInteractive
          >
            <Grid size="grow">
              <DirectionsRunRoundedIcon
                sx={{ fontSize: fontSizeIcon }}
                color={
                  (log.log_summary.quality.pacing?.percent ?? 1) < 1
                    ? "error"
                    : undefined
                }
              />
              <Typography
                sx={{
                  fontSize: fontSize,
                  color: (theme) =>
                    (log.log_summary.quality.pacing?.percent ?? 1) < 1
                      ? theme.palette.error.main
                      : undefined,
                  fontWeight:
                    (log.log_summary.quality.pacing?.percent ?? 1) < 1
                      ? "bold"
                      : undefined,
                }}
              >
                {log.log_summary.quality.pacing
                  ? `${Math.round(log.log_summary.quality.pacing.percent * 100)}%`
                  : "--"}
              </Typography>
            </Grid>
          </Tooltip>
        )}
        {log.log_summary?.quality && (
          <Tooltip
            title={`Range of motion: ${log.log_summary.quality.range_of_motion?.details}`}
            disableInteractive
          >
            <Grid size="grow">
              <SelfImprovementRoundedIcon
                sx={{ fontSize: fontSizeIcon }}
                color={
                  (log.log_summary.quality.range_of_motion?.percent ?? 1) < 1
                    ? "error"
                    : undefined
                }
              />
              <Typography
                sx={{
                  fontSize: fontSize,
                  color: (theme) =>
                    (log.log_summary.quality.range_of_motion?.percent ?? 1) < 1
                      ? theme.palette.error.main
                      : undefined,
                  fontWeight:
                    (log.log_summary.quality.range_of_motion?.percent ?? 1) < 1
                      ? "bold"
                      : undefined,
                }}
              >
                {log.log_summary.quality.range_of_motion
                  ? `${Math.round(
                      log.log_summary.quality.range_of_motion.percent * 100,
                    )}%`
                  : "--"}
              </Typography>
            </Grid>
          </Tooltip>
        )}
        {log.log_summary?.quality && (
          <Tooltip
            title={`Rest: ${log.log_summary.quality.rest?.details}`}
            disableInteractive
          >
            <Grid size="grow">
              <HotelRoundedIcon
                sx={{ fontSize: fontSizeIcon }}
                color={
                  (log.log_summary.quality.rest?.percent ?? 1) < 0.95
                    ? "error"
                    : undefined
                }
              />
              <Typography
                sx={{
                  fontSize: fontSize,
                  color: (theme) =>
                    (log.log_summary.quality.rest?.percent ?? 1) < 0.95
                      ? theme.palette.error.main
                      : undefined,
                  fontWeight:
                    (log.log_summary.quality.rest?.percent ?? 1) < 0.95
                      ? "bold"
                      : undefined,
                }}
              >
                {log.log_summary.quality.rest
                  ? `${Math.round(log.log_summary.quality.rest.percent * 100)}%`
                  : "--"}
              </Typography>
            </Grid>
          </Tooltip>
        )}
      </Grid>
    </Card>
  );
}
