import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import SickRoundedIcon from "@mui/icons-material/SickRounded";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  useDeleteVacation,
  type Vacation,
} from "@trainwell/features/vacations";
import { differenceInHours, format } from "date-fns";
import { useState } from "react";
import TimezoneTypography from "src/components/misc/TimezoneTypography";
import { useAppSelector } from "src/hooks/stateHooks";
import { getTrainerName, getTrainerTimezone } from "src/lib/coachUtility";
import { selectTrainerNames } from "src/slices/trainersSlice";
import VacationEditDialog from "./VacationEditDialog";

interface Props {
  vacation: Vacation;
  disableEditing?: boolean;
}

export function SimpleVacationCell({ vacation, disableEditing }: Props) {
  const trainerNames = useAppSelector(selectTrainerNames);
  const [isVacationDialogOpen, setIsVacationDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const deleteVacation = useDeleteVacation();

  const coachDefaultTimezoneOffset =
    trainerNames.find((trainer) => trainer.trainer_id === vacation?.trainer_id)
      ?.timezone ?? "Etc/UTC";

  if (!vacation) {
    return <Typography>Error loading vacation</Typography>;
  }

  let icon = <MoreHorizRoundedIcon />;

  if (vacation.type === "pto") {
    icon = <AttachMoneyRoundedIcon />;
  } else if (vacation.type === "sick") {
    icon = <SickRoundedIcon />;
  } else if (vacation.type === "wto") {
    icon = <WorkRoundedIcon />;
  }

  const requiresMessageAndDate =
    vacation.type === "sick" ||
    (vacation.type === "pto" &&
      differenceInHours(
        new Date(vacation.date_end),
        new Date(vacation.date_start),
      ) > 48);

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        backgroundColor: (theme) =>
          requiresMessageAndDate && !vacation.message_to_clients
            ? theme.palette.errorSurface.main
            : undefined,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Grid container spacing={2}>
        <Grid size="auto">
          <Avatar
            sx={{
              bgcolor: (theme) => theme.palette.backgroundSecondary.main,
              mr: 2,
              color: (theme) => theme.palette.primary.main,
              width: 32,
              height: 32,
            }}
          >
            {icon}
          </Avatar>
        </Grid>
        <Grid size="grow">
          <Typography>
            {getTrainerName(vacation.trainer_id, trainerNames)}
          </Typography>
          <Typography variant="overline">
            {vacation.type === "pto"
              ? "Paid time off"
              : vacation.type === "sick"
                ? "Sick time"
                : vacation.type === "wto"
                  ? "Work time off"
                  : "Type: other"}
          </Typography>
        </Grid>
        <Grid size={3}>
          <Typography variant="overline">From</Typography>
          <TimezoneTypography
            text={format(
              new Date(vacation.date_start),
              "MMMM do, yyyy h:mm aaa",
            )}
            date={new Date(vacation.date_start)}
            otherTimezone={getTrainerTimezone(
              vacation.trainer_id,
              trainerNames,
            )}
            otherTimezoneLabel="Trainer"
          />
        </Grid>
        <Grid size={3}>
          <Typography variant="overline">To</Typography>
          <TimezoneTypography
            text={format(new Date(vacation.date_end), "MMMM do, yyyy h:mm aaa")}
            date={new Date(vacation.date_end)}
            otherTimezone={getTrainerTimezone(
              vacation.trainer_id,
              trainerNames,
            )}
            otherTimezoneLabel="Trainer"
          />
        </Grid>
        <Grid size={1}>
          {!disableEditing && (
            <IconButton
              aria-label="more"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              disabled={Boolean(
                (vacation.type === "pto" || vacation.type === "sick") &&
                  vacation.date_message_sent_to_clients,
              )}
            >
              <MoreVertRoundedIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {(vacation.type === "pto" || vacation.type === "sick") && (
        <Box sx={{ mt: 1 }}>
          {vacation.message_to_clients && (
            <Typography>
              <b>Mesage:</b> {vacation.message_to_clients}
            </Typography>
          )}
          {vacation.date_message_sent_to_clients ? (
            <Typography>
              <b>Message sent on:</b>{" "}
              <TimezoneTypography
                text={format(
                  new Date(vacation.date_message_sent_to_clients),
                  "MMMM do, yyyy h:mm aaa",
                )}
                date={new Date(vacation.date_message_sent_to_clients)}
                otherTimezone={getTrainerTimezone(
                  vacation.trainer_id,
                  trainerNames,
                )}
                otherTimezoneLabel="Trainer"
              />
            </Typography>
          ) : (
            <Typography>
              <b>Message will be sent on:</b>{" "}
              {vacation.date_to_send_message_to_clients ? (
                <TimezoneTypography
                  text={format(
                    new Date(vacation.date_to_send_message_to_clients),
                    "MMMM do, yyyy h:mm aaa",
                  )}
                  date={new Date(vacation.date_to_send_message_to_clients)}
                  otherTimezone={getTrainerTimezone(
                    vacation.trainer_id,
                    trainerNames,
                  )}
                  otherTimezoneLabel="Trainer"
                />
              ) : (
                "not found"
              )}
            </Typography>
          )}
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        slotProps={{
          list: {
            "aria-labelledby": "basic-button",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setIsVacationDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <EditRoundedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);

            deleteVacation.mutate(vacation.id);
          }}
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          <ListItemIcon color="error">
            <DeleteRoundedIcon
              fontSize="small"
              sx={{ color: (theme) => theme.palette.error.main }}
            />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <VacationEditDialog
        open={isVacationDialogOpen}
        defaultVacation={vacation}
        trainerTimezone={coachDefaultTimezoneOffset}
        saveText="Save"
        titleText="Edit vacation time"
        onClose={() => {
          setIsVacationDialogOpen(false);
        }}
        trainerId={vacation.trainer_id}
      />
    </Box>
  );
}
