import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { TimePicker } from "@mui/x-date-pickers";
import type { Client, ProgressMetricSummary } from "@trainwell/features/legacy";
import { format, parse } from "date-fns";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  updateClient,
  updateProgressMetricSummaries,
} from "src/slices/clientSlice";

const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

interface Props {
  open: boolean;
  client: Pick<Client, "goal" | "settings" | "user_id">;
  onClose: () => void;
}

export default function GoalEditDialog({ open, client, onClose }: Props) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [enableNotifications, setEnableNotifications] = useState(
    !client.settings.progress_metrics?.measurement_notifications_disabled,
  );
  const [time, setTime] = useState<Date | null>(
    parse(
      client.settings.progress_metrics?.measurement_time ?? "09:00",
      "HH:mm",
      new Date(),
    ),
  );
  const [measurementDay, setMeasurementDay] = useState(
    client.settings.progress_metrics?.measurement_day ?? 1,
  );
  const [progressMetricSummaries, setProgressMetricSummaries] = useState(
    client.goal?.progress_metric_summaries ?? [],
  );
  const [loading, setLoading] = useState(false);

  function resetAndClose() {
    setLoading(false);
    setEnableNotifications(
      !client.settings.progress_metrics?.measurement_notifications_disabled,
    );
    setTime(
      parse(
        client.settings.progress_metrics?.measurement_time ?? "09:00",
        "HH:mm",
        new Date(),
      ),
    );
    setMeasurementDay(client.settings.progress_metrics?.measurement_day ?? 1);
    setProgressMetricSummaries(client.goal?.progress_metric_summaries ?? []);

    onClose();
  }

  return (
    <Dialog
      onClose={resetAndClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitleWithClose onClose={resetAndClose}>
        Progress logging schedule
      </DialogTitleWithClose>
      <DialogContent>
        <FormGroup sx={{ mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={enableNotifications}
                onChange={(event) => {
                  setEnableNotifications(event.target.checked);
                }}
              />
            }
            label="Enable progress logging notifications"
          />
        </FormGroup>
        <Grid container spacing={2}>
          <Grid size={6}>
            <Typography sx={{ fontWeight: "bold" }}>Time of day</Typography>
            <Typography variant="overline">
              When should the client get a notification to log progress.
            </Typography>
          </Grid>
          <Grid size={6}>
            <TimePicker
              label="Controlled picker"
              value={time}
              onChange={(newValue) => {
                setTime(newValue);
              }}
              sx={{ my: 1 }}
            />
          </Grid>
        </Grid>
        <Typography sx={{ fontWeight: "bold", mt: 4, mb: 1 }}>
          Day of the week (for weekly metrics)
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={measurementDay}
          exclusive
          onChange={(_event, newValue) => {
            if (newValue === null) {
              return;
            }

            setMeasurementDay(newValue);
          }}
          size="small"
        >
          {days.map((day, dayIndex) => (
            <ToggleButton value={dayIndex} key={day}>
              {day}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {client.goal && (
          <>
            <Typography sx={{ fontWeight: "bold", mt: 4, mb: 1 }}>
              Logging frequency
            </Typography>
            <Stack direction={"column"} spacing={1} divider={<Divider />}>
              {progressMetricSummaries.map((progressMetricSummary) => (
                <Grid container key={progressMetricSummary.id}>
                  <Grid size={6} sx={{ display: "flex", alignItems: "center" }}>
                    <Typography>
                      {progressMetricSummary.progress_metric?.name}
                    </Typography>
                  </Grid>
                  <Grid size={6}>
                    <ToggleButtonGroup
                      color="primary"
                      value={progressMetricSummary.measurement_frequency}
                      exclusive
                      onChange={(_event, newValue) => {
                        if (newValue === null) {
                          return;
                        }

                        const newProgressMetricSummaries = JSON.parse(
                          JSON.stringify(progressMetricSummaries),
                        ) as ProgressMetricSummary[];

                        const index = newProgressMetricSummaries.findIndex(
                          (p) => p.id === progressMetricSummary.id,
                        );

                        newProgressMetricSummaries[
                          index
                        ].measurement_frequency = newValue;

                        setProgressMetricSummaries(newProgressMetricSummaries);
                      }}
                      size="small"
                    >
                      <ToggleButton value={"daily"}>Daily</ToggleButton>
                      <ToggleButton value={"weekly"}>Weekly</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={resetAndClose}>
          Cancel
        </Button>
        <Button
          loading={loading}
          startIcon={<SaveRoundedIcon />}
          variant="contained"
          onClick={() => {
            setLoading(true);

            const promises: Promise<any>[] = [];

            promises.push(
              dispatch(
                updateClient({
                  user_id: client.user_id,
                  // @ts-expect-error
                  "settings.progress_metrics.measurement_notifications_disabled":
                    !enableNotifications,
                  "settings.progress_metrics.measurement_day": measurementDay,
                  "settings.progress_metrics.measurement_time": format(
                    time!,
                    "HH:mm",
                  ),
                }),
              ).unwrap(),
            );

            if (client.goal) {
              promises.push(
                dispatch(
                  updateProgressMetricSummaries(progressMetricSummaries),
                ).unwrap(),
              );
            }

            Promise.all(promises)
              .then(() => {
                resetAndClose();
              })
              .catch(() => {
                setLoading(false);

                enqueueSnackbar("Error saving", { variant: "error" });
              });
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
