/* eslint-disable @typescript-eslint/no-unsafe-call */
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import {
  Box,
  Paper,
  Popover,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { BarChart } from "@mui/x-charts";
import type { CoachMetricTemplateSubmodule } from "@trainwell/features/legacy";
import React, { useState } from "react";
import { queryMetric } from "src/lib/metrics";
import { round } from "src/lib/misc";

const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];

const styles: Record<string, React.CSSProperties> = {
  metricContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "4px",
  },
  metric: {
    fontSize: "20px",
    fontWeight: 500,
  },
  vLine: {
    height: "24px",
    borderRight: "1px solid rgb(0, 0, 0)",
    margin: "0 8px 0 8px",
    flexGrow: 0,
    flexBasis: 0,
  },
};

interface Props {
  metrics: any;
  companyMetrics: any;
  submodule: CoachMetricTemplateSubmodule;
  window: number;
  trainerName: string;
}

export default function Distribution({
  metrics,
  companyMetrics,
  submodule,
  window,
  trainerName,
}: Props) {
  const theme = useTheme();
  const [distributionAnchorEl, setDistributionAnchorEl] =
    useState<HTMLDivElement | null>(null);
  const openDistribution = Boolean(distributionAnchorEl);

  const handleOpenDistribution = (e: React.MouseEvent<HTMLDivElement>) => {
    setDistributionAnchorEl(e.currentTarget);
  };

  const handleCloseDistribution = () => {
    setDistributionAnchorEl(null);
  };

  const offsetHours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23,
  ];

  if (
    submodule.distribution_buckets &&
    submodule.distribution_buckets.length === 24
  ) {
    const timezoneOffset = new Date().getTimezoneOffset() / 60;

    for (let i = 0; i < timezoneOffset; i++) {
      const x = offsetHours.shift();
      offsetHours.push(x!);
    }
  }

  const metric = queryMetric(metrics, submodule.metric);
  const companyMetric = queryMetric(companyMetrics, submodule.metric);

  let trainerData = queryMetric(metrics, submodule.distribution_metric!);
  let companyData = queryMetric(companyMetrics, submodule.distribution_metric!);

  trainerData = trainerData.map((value: any) => {
    value = submodule.per_day ? value / window : value;
    return round(value, submodule.precision);
  });

  companyData = companyData.map((value: any) => {
    value = submodule.per_day ? value / window : value;
    return round(value, submodule.precision);
  });

  const binData = (data: any[], binWidth: number) => {
    const hData: number[][] = [];
    const size = data.length;
    let bins = round(Math.sqrt(size));

    bins = bins > 50 ? 50 : bins;
    const max = Math.max.apply(null, data);
    const min = 0;
    const range = max - min;
    let width = round(range / bins);
    let binBottom: number;
    let binTop: number;

    if (binWidth) {
      width = binWidth;
      bins = round(range / width);
    }

    for (let i = 0; i < bins; i++) {
      binBottom = min + i * width;
      binTop = binBottom + width;

      if (!hData[i]) {
        hData[i] = [];
        hData[i][0] = binBottom + width / 2;
      }

      for (let j = 0; j < size; j++) {
        const x = data[j];

        if (x >= binBottom && x < binTop) {
          if (!hData[i][1]) {
            hData[i][1] = 1;
          } else {
            hData[i][1]++;
          }
        }
      }

      if (hData[i][1] === undefined) {
        // @ts-expect-error
        hData[i][1] = null;
      }
    }

    return hData;
  };

  const setBinWidth = (data: any[]) => {
    const size = data.length;
    const bins = round(Math.sqrt(size));
    const max = Math.max.apply(null, data);
    const min = Math.min.apply(null, data);
    const range = max - min;
    const width = round(range / bins);

    return width;
  };

  const binWidth = round(
    (setBinWidth(trainerData) + setBinWidth(companyData)) / 2,
  );

  // Array of arrays of form [x, y]
  const trainerDataBinned = binData(trainerData, binWidth);
  // Array of arrays of form [x, y]
  const companyDataBinned = binData(companyData, binWidth);

  const chartDataBinned: {
    x: number;
    trainer: number | null;
    company: number | null;
  }[] = [];

  trainerDataBinned.forEach(([x, trainer]) => {
    const companyData = companyDataBinned.find(([cx]) => cx === x);
    chartDataBinned.push({
      x,
      trainer,
      company: companyData ? companyData[1] : null,
    });
  });

  companyDataBinned.forEach(([x, company]) => {
    if (!chartDataBinned.find((data) => data.x === x)) {
      chartDataBinned.push({
        x,
        trainer: null,
        company,
      });
    }
  });

  const chartDataBucket = hours.map((h, i) => {
    return {
      x: submodule.distribution_buckets?.at(i) ?? h,
      trainer: trainerData[offsetHours[h]],
      company: companyData[offsetHours[h]],
    };
  });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
        flexGrow: 1,
        margin: "0 2px 0 2px",
        borderRadius: "4px",
      }}
      aria-owns={openDistribution ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={handleOpenDistribution}
      onMouseLeave={handleCloseDistribution}
    >
      <BarChartRoundedIcon style={{ fontSize: "18px" }} />
      <Popover
        open={openDistribution}
        anchorEl={distributionAnchorEl}
        sx={{
          pointerEvents: "none",
        }}
        slotProps={{
          paper: {
            sx: {
              pointerEvents: "auto",
            },
          },
        }}
        onClose={handleCloseDistribution}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper
          sx={{
            padding: 3,
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ mb: 1 }}>
            {submodule.metric_title}
          </Typography>
          <Grid style={styles.metricContainer}>
            <div style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}>
              <Typography style={styles.metric}>{metric}</Typography>
            </div>
            <div style={styles.vLine}></div>
            <div style={{ flexGrow: 1, flexBasis: 0, textAlign: "center" }}>
              <Typography style={styles.metric}>{companyMetric}</Typography>
            </div>
          </Grid>
          {!submodule.distribution_buckets && (
            <BarChart
              colors={[theme.palette.primary.main]}
              dataset={chartDataBinned}
              yAxis={[
                {
                  id: "trainer",
                  label:
                    `${trainerName === "tw" ? "trainwell" : trainerName}'s ` +
                    submodule.metric_title,
                },
                {
                  id: "company",
                  label: "trainwell's " + submodule.metric_title,
                },
              ]}
              rightAxis={"company"}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "x",
                  label: submodule.metric_title,
                  valueFormatter: (value) =>
                    `${value - binWidth / 2}-${value + binWidth / 2}`,
                },
              ]}
              series={[
                {
                  type: "bar",
                  dataKey: "trainer",
                  label: trainerName === "tw" ? "trainwell" : trainerName,
                  color: theme.palette.primary.main,
                  stack: "a",
                  yAxisId: "trainer",
                },
                {
                  type: "bar",
                  dataKey: "company",
                  label: "trainwell",
                  color: alpha("#000000", 0.5),
                  stack: "a",
                  yAxisId: "company",
                },
              ]}
              width={500}
              height={300}
              skipAnimation
            />
          )}
          {submodule.distribution_buckets && (
            <BarChart
              colors={[theme.palette.primary.main]}
              dataset={chartDataBucket}
              yAxis={[
                {
                  id: "trainer",
                  label:
                    `${trainerName === "tw" ? "trainwell" : trainerName}'s ` +
                    submodule.metric_title,
                },
                {
                  id: "company",
                  label: "trainwell's " + submodule.metric_title,
                },
              ]}
              rightAxis={"company"}
              xAxis={[
                {
                  scaleType: "band",
                  dataKey: "x",
                  label: "Hour",
                },
              ]}
              series={[
                {
                  type: "bar",
                  dataKey: "trainer",
                  label: trainerName === "tw" ? "trainwell" : trainerName,
                  color: theme.palette.primary.main,
                  stack: "a",
                  yAxisId: "trainer",
                },
                {
                  type: "bar",
                  dataKey: "company",
                  label: "trainwell",
                  color: theme.palette.text.secondary,
                  stack: "a",
                  yAxisId: "company",
                },
              ]}
              width={500}
              height={300}
              skipAnimation
            />
          )}
        </Paper>
      </Popover>
    </Box>
  );
}
