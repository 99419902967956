import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { Trainer } from "@trainwell/features/legacy";
import TimeSelector from "src/components/misc/TimeSelector";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function NotificationsSettings() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);

  if (!trainer) {
    return null;
  }

  return (
    <>
      <Typography variant="h2">Push notifications</Typography>
      <Typography sx={{ my: 1.5 }}>
        These options control the push notifications you get on your phone if
        you have the Trainer App installed.
      </Typography>
      <FormGroup sx={{ mb: 1.5 }}>
        <FormControlLabel
          control={
            <Switch
              checked={trainer.settings.notifications.enabled}
              onChange={(event) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "settings.notifications.enabled": event.target.checked,
                  }),
                );
              }}
            />
          }
          label="Enable notifications"
        />
      </FormGroup>
      <FormGroup sx={{ mb: 1.5 }}>
        <FormControlLabel
          disabled={!trainer.settings.notifications.enabled}
          control={
            <Switch
              checked={trainer.settings.notifications.enable_new_client}
              onChange={(event) => {
                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "settings.notifications.enable_new_client":
                      event.target.checked,
                  }),
                );
              }}
            />
          }
          label="New client notifications"
        />
      </FormGroup>
      <Typography sx={{ fontWeight: "bold", mb: 1 }}>
        Allow notifications:
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl
          sx={{ maxWidth: 300, mr: 2 }}
          size="small"
          disabled={!trainer.settings.notifications.enabled}
        >
          <Select
            value={trainer.settings.notifications.schedule}
            onChange={(event) => {
              dispatch(
                updateTrainer({
                  trainer_id: trainer.trainer_id,
                  // @ts-expect-error
                  "settings.notifications.schedule": event.target.value,
                }),
              );
            }}
          >
            <MenuItem value={"every_day"}>Every day</MenuItem>
            <MenuItem value={"weekdays"}>Weekdays</MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </FormControl>
        {trainer.settings.notifications.schedule !== "custom" && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ mr: 2 }}>From</Typography>
            <TimeSelector
              size="small"
              disabled={!trainer.settings.notifications.enabled}
              sx={{ mr: 2 }}
              value={trainer.settings.notifications.custom_times[0].time_start}
              onSave={(newTime) => {
                const newTimes =
                  trainer.settings.notifications.custom_times.map((t) => {
                    return { ...t, time_start: newTime };
                  });

                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "settings.notifications.custom_times": newTimes,
                  }),
                );
              }}
            />
            <Typography sx={{ mr: 2 }}>To</Typography>
            <TimeSelector
              size="small"
              disabled={!trainer.settings.notifications.enabled}
              sx={{ mr: 2 }}
              value={trainer.settings.notifications.custom_times[0].time_end}
              onSave={(newTime) => {
                const newTimes =
                  trainer.settings.notifications.custom_times.map((t) => {
                    return { ...t, time_end: newTime };
                  });

                dispatch(
                  updateTrainer({
                    trainer_id: trainer.trainer_id,
                    // @ts-expect-error
                    "settings.notifications.custom_times": newTimes,
                  }),
                );
              }}
            />
          </Box>
        )}
      </Box>
      {trainer.settings.notifications.schedule === "custom" && (
        <Stack
          spacing={1}
          direction={"column"}
          sx={{ maxWidth: "450px", mt: 2 }}
        >
          {days.map((day, dayIndex) => (
            <Grid container key={day} sx={{ alignItems: "center" }}>
              <Grid size={3}>
                <Typography>{day}: </Typography>
              </Grid>
              <Grid
                size={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <TimeSelector
                  size="small"
                  disabled={!trainer.settings.notifications.enabled}
                  value={
                    trainer.settings.notifications.custom_times[dayIndex]
                      .time_start
                  }
                  onSave={(newTime) => {
                    const newTimes = JSON.parse(
                      JSON.stringify(
                        trainer.settings.notifications.custom_times,
                      ),
                    ) as Trainer["settings"]["notifications"]["custom_times"];
                    newTimes[dayIndex].time_start = newTime;

                    dispatch(
                      updateTrainer({
                        trainer_id: trainer.trainer_id,
                        // @ts-expect-error
                        "settings.notifications.custom_times": newTimes,
                      }),
                    );
                  }}
                />
              </Grid>
              <Grid size={1}>
                <Typography sx={{ textAlign: "center" }}>to</Typography>
              </Grid>
              <Grid size={4}>
                <TimeSelector
                  size="small"
                  disabled={!trainer.settings.notifications.enabled}
                  value={
                    trainer.settings.notifications.custom_times[dayIndex]
                      .time_end
                  }
                  onSave={(newTime) => {
                    const newTimes = JSON.parse(
                      JSON.stringify(
                        trainer.settings.notifications.custom_times,
                      ),
                    ) as Trainer["settings"]["notifications"]["custom_times"];
                    newTimes[dayIndex].time_end = newTime;

                    dispatch(
                      updateTrainer({
                        trainer_id: trainer.trainer_id,
                        // @ts-expect-error
                        "settings.notifications.custom_times": newTimes,
                      }),
                    );
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Stack>
      )}
    </>
  );
}
