import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import VerticalAlignTopRoundedIcon from "@mui/icons-material/VerticalAlignTopRounded";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { ClientRampUpStage } from "@trainwell/features/legacy";
import { useEffect, useState } from "react";
import ControlBar from "src/components/misc/ControlBar";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectClientLoad } from "src/slices/clientsSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import { updateTrainer } from "src/slices/trainersSlice";

export default function ClientLoadPage() {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [netClients, setNetClients] = useState<null | number>(null);
  const [clientCapacity, setClientCapacity] = useState<null | number>(null);
  const [clientCapacityTarget, setClientCapacityTarget] = useState<
    null | number
  >(null);
  const clientLoad = useAppSelector(selectClientLoad);
  const [clientRampUpStages, setClientRampUpStages] = useState<
    ClientRampUpStage[] | null
  >(null);

  const currentCLientRampUpStage = clientRampUpStages?.find(
    (stage) => stage.id === trainer?.client_ramp_up_stage_id,
  );

  useEffect(() => {
    api.clientRampUpStages.getAll().then((data) => {
      data.sort((a, b) => {
        if (a.type === "onboarding") {
          return 0;
        } else if (b.type === "onboarding") {
          return 1;
        }

        const difference = a.n_clients_max - b.n_clients_max;

        if (difference === 0) {
          if (a.type === "ramp_up" && b.type === "review_period") {
            return -1;
          } else if (a.type === "review_period" && b.type === "ramp_up") {
            return 1;
          }

          return 0;
        } else {
          return a.n_clients_max - b.n_clients_max;
        }
      });

      setClientRampUpStages(data);
    });
  }, []);

  if (!trainer) {
    return null;
  }

  return (
    <Box>
      <ControlBar
        leftContent={
          <Button
            variant="contained"
            href="/"
            startIcon={<ArrowBackRoundedIcon />}
            size="small"
          >
            Back
          </Button>
        }
      />
      <Container maxWidth="md" sx={{ p: 3 }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          Client load
        </Typography>
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid size={4}>
            <Card
              variant="outlined"
              sx={{
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <Typography variant="body2">
                    All time max client load
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                    {trainer.client_load_max}
                  </Typography>
                </Box>
                <Tooltip
                  title="Highest visible client load you've ever had"
                  placement="top"
                >
                  <IconButton size="small">
                    <HelpOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          </Grid>
          <Grid size={4}>
            <Card
              variant="outlined"
              sx={{
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <Typography variant="body2">Client load</Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                    {clientLoad}
                  </Typography>
                </Box>
                <Tooltip
                  title="Visible clients. Does not include interim clients"
                  placement="top"
                >
                  <IconButton size="small">
                    <HelpOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          </Grid>
          <Grid size={4}>
            <Card
              variant="outlined"
              sx={{
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Box>
                  <Typography variant="body2">
                    Clients with 6+ months tenure
                  </Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                    {trainer.n_clients_6_months_tenure}
                  </Typography>
                </Box>
                <Tooltip
                  title="Number of visible clients who have a tenure of 6 months (have been around and paid for 6+ months total)"
                  placement="top"
                >
                  <IconButton size="small">
                    <HelpOutlineRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <VerticalAlignTopRoundedIcon color="inherit" sx={{ mr: 1 }} />
            <Typography variant="h2">Client capacity</Typography>
          </Box>
          <Typography sx={{ my: 1.5 }}>
            Maximum number of visible clients you can have
          </Typography>
          <Grid container spacing={2}>
            <Grid size={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  borderWidth:
                    trainer.client_ramp_up_stage_id !== "ramp_up_160_plus"
                      ? 2
                      : undefined,
                  borderColor: (theme) =>
                    trainer.client_ramp_up_stage_id !== "ramp_up_160_plus"
                      ? theme.palette.success.main
                      : undefined,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2">Current</Typography>
                      {trainer.client_ramp_up_stage_id !==
                        "ramp_up_160_plus" && (
                        <Chip
                          color="success"
                          icon={<CheckRoundedIcon />}
                          size="small"
                          sx={{ ml: 1 }}
                          label="Active"
                        />
                      )}
                    </Box>
                    {clientCapacity === null ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                          {trainer.client_capacity}
                        </Typography>
                        <Tooltip
                          title={
                            trainer.client_ramp_up_stage_id !==
                            "ramp_up_160_plus"
                              ? "Must have 160+ clients to edit"
                              : ""
                          }
                          disableInteractive
                        >
                          <span>
                            <Button
                              size="small"
                              onClick={() => {
                                setClientCapacity(
                                  trainer.client_capacity ?? 160,
                                );
                              }}
                              variant="text"
                              startIcon={<EditRoundedIcon />}
                              sx={{ ml: 2 }}
                              disabled={
                                trainer.client_ramp_up_stage_id !==
                                "ramp_up_160_plus"
                              }
                            >
                              Edit
                            </Button>
                          </span>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <TextField
                          label="Client cap"
                          value={clientCapacity}
                          onChange={(event) => {
                            setClientCapacity(Number(event.target.value));
                          }}
                          size="small"
                        />
                        <Button
                          size="small"
                          onClick={() => {
                            dispatch(
                              updateTrainer({
                                trainer_id: trainer.trainer_id,
                                client_capacity: Math.max(
                                  clientCapacity ?? 160,
                                  160,
                                ),
                              }),
                            ).then(() => {
                              setClientCapacity(null);
                            });
                          }}
                          startIcon={<SaveRoundedIcon />}
                          sx={{ ml: 2 }}
                        >
                          Save
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Tooltip title="Set by trainwell" placement="top">
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  borderWidth:
                    trainer.client_ramp_up_stage_id === "ramp_up_160_plus"
                      ? 2
                      : undefined,
                  borderColor: (theme) =>
                    trainer.client_ramp_up_stage_id === "ramp_up_160_plus"
                      ? theme.palette.success.main
                      : undefined,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2">
                        Target (must have 160+ clients)
                      </Typography>
                      {trainer.client_ramp_up_stage_id ===
                        "ramp_up_160_plus" && (
                        <Chip
                          color="success"
                          icon={<CheckRoundedIcon />}
                          size="small"
                          sx={{ ml: 1 }}
                          label="Active"
                        />
                      )}
                    </Box>
                    {clientCapacityTarget === null ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                          {trainer.client_capacity_target_manual}
                        </Typography>
                        <Button
                          size="small"
                          onClick={() => {
                            setClientCapacityTarget(
                              trainer.client_capacity_target_manual ?? 160,
                            );
                          }}
                          variant="text"
                          startIcon={<EditRoundedIcon />}
                          sx={{ ml: 2 }}
                        >
                          Edit
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <TextField
                          label="Target"
                          value={clientCapacityTarget}
                          onChange={(event) => {
                            setClientCapacityTarget(Number(event.target.value));
                          }}
                          size="small"
                        />
                        <Button
                          size="small"
                          onClick={() => {
                            dispatch(
                              updateTrainer({
                                trainer_id: trainer.trainer_id,
                                client_capacity_target_manual: Math.max(
                                  clientCapacityTarget ?? 160,
                                  160,
                                ),
                              }),
                            ).then(() => {
                              setClientCapacityTarget(null);
                            });
                          }}
                          startIcon={<SaveRoundedIcon />}
                          sx={{ ml: 2 }}
                        >
                          Save
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Tooltip title="Set by you" placement="top">
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Card>
        <Card variant="outlined" sx={{ p: 3, mb: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TrendingUpRoundedIcon color="inherit" sx={{ mr: 1 }} />
            <Typography variant="h2">Net clients per week</Typography>
          </Box>
          <Typography sx={{ my: 1.5 }}>
            Maximum number your visible clients can increase each week. This is
            NOT the max number of <i>new</i> clients you can get each week.
            <br />
            For example, if you lose 5 clients and gain 6 clients in a week, you
            have gained <b>1</b> net client from the start of the week
          </Typography>
          <Grid container spacing={2}>
            <Grid size={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  borderWidth:
                    trainer.client_ramp_up_stage_id !== "ramp_up_160_plus"
                      ? 2
                      : undefined,
                  borderColor: (theme) =>
                    trainer.client_ramp_up_stage_id !== "ramp_up_160_plus"
                      ? theme.palette.success.main
                      : undefined,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2">Default</Typography>
                      {trainer.client_ramp_up_stage_id !==
                        "ramp_up_160_plus" && (
                        <Chip
                          color="success"
                          icon={<CheckRoundedIcon />}
                          size="small"
                          sx={{ ml: 1 }}
                          label="Active"
                        />
                      )}
                    </Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                      {currentCLientRampUpStage?.net_clients_ramp_up ?? 0}
                    </Typography>
                  </Box>
                  <Tooltip
                    title="Set by trainwell. Number of clients per week you'll net if
            you're under the 160 client cap"
                    placement="top"
                  >
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card
                variant="outlined"
                sx={{
                  p: 1,
                  borderWidth:
                    trainer.client_ramp_up_stage_id === "ramp_up_160_plus"
                      ? 2
                      : undefined,
                  borderColor: (theme) =>
                    trainer.client_ramp_up_stage_id === "ramp_up_160_plus"
                      ? theme.palette.success.main
                      : undefined,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="body2">
                        Preferred (must have 160+ clients)
                      </Typography>
                      {trainer.client_ramp_up_stage_id ===
                        "ramp_up_160_plus" && (
                        <Chip
                          color="success"
                          icon={<CheckRoundedIcon />}
                          size="small"
                          sx={{ ml: 1 }}
                          label="Active"
                        />
                      )}
                    </Box>
                    {netClients === null ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontWeight: "bold", fontSize: 28 }}>
                          {trainer.net_clients_ramp_up_manual}
                        </Typography>
                        <Button
                          size="small"
                          onClick={() => {
                            setNetClients(
                              trainer.net_clients_ramp_up_manual ?? 1,
                            );
                          }}
                          variant="text"
                          startIcon={<EditRoundedIcon />}
                          sx={{ ml: 2 }}
                        >
                          Edit
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        sx={{ display: "flex", alignItems: "center", mt: 1 }}
                      >
                        <TextField
                          label="Preferred"
                          value={netClients}
                          onChange={(event) => {
                            setNetClients(Number(event.target.value));
                          }}
                          size="small"
                        />
                        <Button
                          size="small"
                          onClick={() => {
                            dispatch(
                              updateTrainer({
                                trainer_id: trainer.trainer_id,
                                net_clients_ramp_up_manual: Math.max(
                                  netClients ?? 1,
                                  1,
                                ),
                              }),
                            ).then(() => {
                              setNetClients(null);
                            });
                          }}
                          startIcon={<SaveRoundedIcon />}
                          sx={{ ml: 2 }}
                        >
                          Save
                        </Button>
                      </Box>
                    )}
                  </Box>
                  <Tooltip
                    title="Set by you. Number of clients per week you'll net if
            you're over the 160 client cap"
                    placement="top"
                  >
                    <IconButton size="small">
                      <HelpOutlineRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Card>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Ramp up stage
        </Typography>
        <Stack direction={"column"} spacing={2} sx={{ maxWidth: "400px" }}>
          {clientRampUpStages?.map((stage) => (
            <Box key={stage.id}>
              <Card
                variant="outlined"
                sx={{
                  p: 2,
                  borderWidth:
                    trainer.client_ramp_up_stage_id === stage.id
                      ? 2
                      : undefined,
                  borderColor: (theme) =>
                    trainer.client_ramp_up_stage_id === stage.id
                      ? theme.palette.success.main
                      : undefined,
                }}
              >
                <Stack spacing={1.5}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mb: stage.type === "onboarding" ? -1.5 : undefined,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {stage.name}
                    </Typography>
                    {trainer.client_ramp_up_stage_id === stage.id && (
                      <Chip
                        color="success"
                        icon={<CheckRoundedIcon />}
                        size="small"
                        sx={{ ml: 1 }}
                        label="Current stage"
                      />
                    )}
                  </Box>
                  <Box>
                    {stage.type === "ramp_up" && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <PeopleRoundedIcon color="inherit" sx={{ mr: 1 }} />
                        <Typography>
                          {stage.n_clients_min === 160
                            ? "160+ clients"
                            : `${stage.n_clients_min} - ${stage.n_clients_max} clients`}
                        </Typography>
                      </Box>
                    )}
                    {stage.net_clients_ramp_up !== null &&
                      stage.net_clients_ramp_up !== undefined && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TrendingUpRoundedIcon
                            color="inherit"
                            sx={{ mr: 1 }}
                          />
                          <Typography>
                            {stage.net_clients_ramp_up} net clients per week
                          </Typography>
                        </Box>
                      )}
                    {stage.type === "review_period" && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CalendarMonthRoundedIcon
                          color="inherit"
                          sx={{ mr: 1 }}
                        />
                        <Typography>{stage.length_days} days</Typography>
                      </Box>
                    )}
                  </Box>
                  {stage.n_clients_min === 160 && (
                    <Typography>
                      Once you get here you can set a preferred number for
                      client capacity and net clients per week
                    </Typography>
                  )}
                </Stack>
              </Card>
            </Box>
          ))}
        </Stack>
      </Container>
    </Box>
  );
}
