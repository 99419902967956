import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  debounce,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Popover,
  Stack,
  Switch,
  TextField,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as Sentry from "@sentry/react";
import {
  EquipmentType,
  type EquipmentObject,
} from "@trainwell/features/legacy";
import { getConvertedWeight } from "@trainwell/workout-lib";
import cloneDeep from "lodash-es/cloneDeep";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual } from "react-redux";
import SetTextField from "src/features/workout-builder/SetTextField";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getWeightUnit } from "src/lib/miscUtility";
import { api } from "src/lib/trainwellApi";
import { updateClientLocal } from "src/slices/clientSlice";

interface Props {
  userId: string;
  condensed?: boolean;
  defaultCollapsed?: boolean;
  sx?: SxProps<Theme>;
}

export function ClientEquipment({
  userId,
  defaultCollapsed,
  condensed,
  sx = [],
}: Props) {
  const dispatch = useAppDispatch();
  const equipment = useAppSelector(
    (state) =>
      (state.client.client?.user_id === userId
        ? state.client.client.equipment_detailed
        : undefined) ?? {},
    shallowEqual,
  );
  const preferredWeightSystem =
    useAppSelector((state) =>
      state.client.client?.user_id === userId
        ? state.client.client.preferred_weight_system
        : undefined,
    ) ?? "imperial";
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedEquipmentKey, setSelectedEquipmentKey] = useState<
    keyof typeof EquipmentType | null
  >(null);
  const open = Boolean(anchorEl);
  const [collapsed, setCollapsed] = useState(defaultCollapsed ?? false);

  const handleSaveEquipment = useCallback(
    (userId: string, equipment: EquipmentObject) => {
      console.log(`Autosave: saving equipment`, userId);

      const newEquipmentCopy = JSON.parse(
        JSON.stringify(equipment),
      ) as EquipmentObject;

      const equipmentWithWeights: (keyof EquipmentObject)[] = [
        "dumbbell",
        "barbell",
        "kettlebell",
        "medicine_ball",
      ];

      for (const equipmentKey of equipmentWithWeights) {
        newEquipmentCopy[equipmentKey]?.values?.map((value, i) => {
          if (value === null) {
            newEquipmentCopy[equipmentKey]?.values!.splice(i, 1);
            newEquipmentCopy[equipmentKey]?.is_adjustable!.splice(i, 1);
          }
        });
      }

      api.clients
        .updateOne({
          user_id: userId,
          equipment_detailed: newEquipmentCopy,
        })
        .then(() => {
          console.log(`Autosave: saved equipment`, userId);
        })
        .catch((error: unknown) => {
          enqueueSnackbar("Error saving equipment", {
            variant: "error",
          });

          Sentry.captureException(new Error("Error saving equipment"));

          throw error;
        });
    },
    [dispatch, enqueueSnackbar],
  );

  const debouncedSaveEquipment = useMemo(() => {
    return debounce(handleSaveEquipment, 3000);
  }, [handleSaveEquipment]);

  const didMount = useRef(false);
  useEffect(() => {
    didMount.current = false;
    debouncedSaveEquipment.clear();
  }, [userId, debouncedSaveEquipment]);

  useEffect(() => {
    if (!equipment) {
      return;
    }

    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    const newEquipmentCopy = JSON.parse(
      JSON.stringify(equipment),
    ) as EquipmentObject;

    debouncedSaveEquipment(userId, newEquipmentCopy);
  }, [dispatch, JSON.stringify(equipment), debouncedSaveEquipment]);

  const sortedEquipmentKeys = (
    Object.keys(EquipmentType) as (keyof typeof EquipmentType)[]
  ).sort((a, b) => {
    const hasEquipmentA =
      Object.prototype.hasOwnProperty.call(equipment, a) && equipment[a];
    const hasEquipmentB =
      Object.prototype.hasOwnProperty.call(equipment, b) && equipment[b];

    if (hasEquipmentA && !hasEquipmentB) {
      return -1;
    } else if (!hasEquipmentA && hasEquipmentB) {
      return 1;
    }

    return a < b ? -1 : 1;
  });

  const selectedEquipmentKeys = sortedEquipmentKeys.filter(
    (item) =>
      Object.prototype.hasOwnProperty.call(equipment, item) && equipment[item],
  );

  function toggleEquipment(key: keyof typeof EquipmentType) {
    const newSelectedEquipment = cloneDeep(equipment);

    if (newSelectedEquipment[key]) {
      newSelectedEquipment[key] = null;
    } else {
      newSelectedEquipment[key] = {
        notes: null,
        photo_url: null,
      };

      if (
        key === "kettlebell" ||
        key === "dumbbell" ||
        key === "barbell" ||
        key === "medicine_ball"
      ) {
        newSelectedEquipment[key].is_adjustable = [];
        newSelectedEquipment[key].values = [];
      }
    }

    dispatch(
      updateClientLocal({
        user_id: userId,
        equipment_detailed: newSelectedEquipment,
      }),
    );
  }

  return (
    <Box sx={sx}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
        }}
      >
        {sortedEquipmentKeys.map((key) => {
          return (
            <Chip
              key={key}
              label={EquipmentType[key]}
              onClick={(event) => {
                if (condensed) {
                  setSelectedEquipmentKey(key);
                  setAnchorEl(event.currentTarget);
                } else {
                  toggleEquipment(key);
                }
              }}
              size="small"
              variant={!equipment[key] ? "outlined" : "filled"}
              color={!equipment[key] ? "default" : "purpleSurface"}
            />
          );
        })}
      </Box>
      {selectedEquipmentKeys.length > 0 && !condensed && !collapsed && (
        <Stack spacing={2} divider={<Divider />} sx={{ mt: 2 }}>
          {selectedEquipmentKeys.map((key) => {
            return (
              <Box key={key}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 1,
                  }}
                >
                  {EquipmentType[key]}
                </Typography>
                <TextField
                  label="Notes"
                  variant="outlined"
                  size="small"
                  fullWidth
                  multiline={true}
                  defaultValue={equipment[key]?.notes}
                  key={equipment[key]?.notes}
                  sx={{ mb: 1 }}
                  onBlur={(event) => {
                    const newSelectedEquipment = cloneDeep(equipment);

                    if (!event.target.value.replace(/\s/g, "").length) {
                      newSelectedEquipment[key]!.notes = null;
                    } else {
                      newSelectedEquipment[key]!.notes = event.target.value;
                    }

                    dispatch(
                      updateClientLocal({
                        user_id: userId,
                        equipment_detailed: newSelectedEquipment,
                      }),
                    );
                  }}
                />
                {(key === "dumbbell" ||
                  key === "barbell" ||
                  key === "kettlebell" ||
                  key === "medicine_ball") && (
                  <>
                    {equipment[key]?.values?.map((value, i) => {
                      return (
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          key={i + "-" + preferredWeightSystem}
                        >
                          <Grid size="grow">
                            <SetTextField
                              status={"valid"}
                              label={
                                (equipment[key]!.is_adjustable![i]
                                  ? "Max weight"
                                  : "Weight") +
                                " (" +
                                getWeightUnit(preferredWeightSystem) +
                                ")"
                              }
                              value={
                                value === null
                                  ? ""
                                  : getConvertedWeight({
                                      weight: value,
                                      fromSystem: "imperial",
                                      toSystem: preferredWeightSystem,
                                      round: true,
                                    })
                              }
                              onChange={(value) => {
                                const newSelectedEquipment =
                                  cloneDeep(equipment);

                                // @ts-expect-error
                                newSelectedEquipment[key]!.values![i] =
                                  value === ""
                                    ? null
                                    : getConvertedWeight({
                                        weight: Number(
                                          value.replace(/[^\d.]/g, ""),
                                        ),
                                        fromSystem: preferredWeightSystem,
                                        toSystem: "imperial",
                                      });

                                dispatch(
                                  updateClientLocal({
                                    user_id: userId,
                                    equipment_detailed: newSelectedEquipment,
                                  }),
                                );
                              }}
                              allowDecimal
                            />
                          </Grid>
                          <Grid size="auto">
                            {key !== "medicine_ball" && (
                              <FormGroup>
                                <FormControlLabel
                                  sx={{ mr: 1 }}
                                  control={
                                    <Checkbox
                                      size="small"
                                      checked={
                                        equipment[key]?.is_adjustable![i]
                                      }
                                      onChange={() => {
                                        const newSelectedEquipment =
                                          cloneDeep(equipment);

                                        newSelectedEquipment[
                                          key
                                        ]!.is_adjustable![i] =
                                          !newSelectedEquipment[key]!
                                            .is_adjustable![i];

                                        dispatch(
                                          updateClientLocal({
                                            user_id: userId,
                                            equipment_detailed:
                                              newSelectedEquipment,
                                          }),
                                        );
                                      }}
                                    />
                                  }
                                  label="Adjustable"
                                />
                              </FormGroup>
                            )}
                          </Grid>
                          <Grid size="auto">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                const newSelectedEquipment =
                                  cloneDeep(equipment);

                                newSelectedEquipment[
                                  key
                                ]!.is_adjustable!.splice(i, 1);

                                newSelectedEquipment[key]!.values!.splice(i, 1);

                                dispatch(
                                  updateClientLocal({
                                    user_id: userId,
                                    equipment_detailed: newSelectedEquipment,
                                  }),
                                );
                              }}
                              size="small"
                              color="error"
                            >
                              <DeleteRoundedIcon fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<AddRoundedIcon />}
                      onClick={() => {
                        const newSelectedEquipment = cloneDeep(equipment);

                        if (newSelectedEquipment[key]!.is_adjustable) {
                          newSelectedEquipment[key]!.is_adjustable.push(false);
                        } else {
                          newSelectedEquipment[key]!.is_adjustable = [false];
                        }

                        if (newSelectedEquipment[key]!.values) {
                          newSelectedEquipment[key]!.values.push(0);
                        } else {
                          newSelectedEquipment[key]!.values = [0];
                        }

                        dispatch(
                          updateClientLocal({
                            user_id: userId,
                            equipment_detailed: newSelectedEquipment,
                          }),
                        );
                      }}
                    >
                      Add a weight
                    </Button>
                  </>
                )}
              </Box>
            );
          })}
        </Stack>
      )}
      {defaultCollapsed && (
        <Button
          variant="outlined"
          startIcon={
            collapsed ? <ExpandMoreRoundedIcon /> : <ExpandLessRoundedIcon />
          }
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          sx={{ mt: 2 }}
          size="small"
        >
          Details
        </Button>
      )}
      {condensed && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: { width: 400, p: 2 },
            },
          }}
        >
          {selectedEquipmentKey && (
            <Box key={selectedEquipmentKey}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {EquipmentType[selectedEquipmentKey]}
                </Typography>
                <Switch
                  checked={Boolean(equipment[selectedEquipmentKey])}
                  onChange={() => {
                    toggleEquipment(selectedEquipmentKey);
                  }}
                  slotProps={{
                    input: {
                      "aria-label": "controlled",
                    },
                  }}
                />
              </Box>
              {(selectedEquipmentKey === "dumbbell" ||
                selectedEquipmentKey === "barbell" ||
                selectedEquipmentKey === "kettlebell" ||
                selectedEquipmentKey === "medicine_ball") && (
                <>
                  {equipment[selectedEquipmentKey]?.values?.map((value, i) => {
                    return (
                      <Grid
                        container
                        spacing={1}
                        sx={{ mb: 1 }}
                        alignItems="center"
                        key={i + "-" + preferredWeightSystem}
                      >
                        <Grid size="grow">
                          <SetTextField
                            status={"valid"}
                            label={
                              (equipment[selectedEquipmentKey]!.is_adjustable![
                                i
                              ]
                                ? "Max weight"
                                : "Weight") +
                              " (" +
                              getWeightUnit(preferredWeightSystem) +
                              ")"
                            }
                            value={
                              value === null
                                ? ""
                                : getConvertedWeight({
                                    weight: value,
                                    fromSystem: "imperial",
                                    toSystem: preferredWeightSystem,
                                    round: true,
                                  })
                            }
                            onChange={(value) => {
                              const newSelectedEquipment = cloneDeep(equipment);

                              // @ts-expect-error
                              newSelectedEquipment[
                                selectedEquipmentKey
                              ]!.values![i] =
                                value === ""
                                  ? null
                                  : getConvertedWeight({
                                      weight: Number(
                                        value.replace(/[^\d.]/g, ""),
                                      ),
                                      fromSystem: preferredWeightSystem,
                                      toSystem: "imperial",
                                    });

                              dispatch(
                                updateClientLocal({
                                  user_id: userId,
                                  equipment_detailed: newSelectedEquipment,
                                }),
                              );
                            }}
                            allowDecimal
                          />
                        </Grid>
                        <Grid size="auto">
                          {selectedEquipmentKey !== "medicine_ball" && (
                            <FormGroup>
                              <FormControlLabel
                                sx={{ mr: 1 }}
                                control={
                                  <Checkbox
                                    size="small"
                                    checked={
                                      equipment[selectedEquipmentKey]
                                        ?.is_adjustable![i]
                                    }
                                    onChange={() => {
                                      const newSelectedEquipment =
                                        cloneDeep(equipment);

                                      newSelectedEquipment[
                                        selectedEquipmentKey
                                      ]!.is_adjustable![i] =
                                        !newSelectedEquipment[
                                          selectedEquipmentKey
                                        ]!.is_adjustable![i];

                                      dispatch(
                                        updateClientLocal({
                                          user_id: userId,
                                          equipment_detailed:
                                            newSelectedEquipment,
                                        }),
                                      );
                                    }}
                                  />
                                }
                                label="Adjustable"
                              />
                            </FormGroup>
                          )}
                        </Grid>
                        <Grid size="auto">
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              const newSelectedEquipment = cloneDeep(equipment);

                              newSelectedEquipment[
                                selectedEquipmentKey
                              ]!.is_adjustable!.splice(i, 1);

                              newSelectedEquipment[
                                selectedEquipmentKey
                              ]!.values!.splice(i, 1);

                              dispatch(
                                updateClientLocal({
                                  user_id: userId,
                                  equipment_detailed: newSelectedEquipment,
                                }),
                              );
                            }}
                            size="small"
                            color="error"
                          >
                            <DeleteRoundedIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<AddRoundedIcon />}
                    onClick={() => {
                      const newSelectedEquipment = cloneDeep(equipment);

                      if (
                        newSelectedEquipment[selectedEquipmentKey]!
                          .is_adjustable
                      ) {
                        newSelectedEquipment[
                          selectedEquipmentKey
                        ]!.is_adjustable.push(false);
                      } else {
                        newSelectedEquipment[
                          selectedEquipmentKey
                        ]!.is_adjustable = [false];
                      }

                      if (newSelectedEquipment[selectedEquipmentKey]!.values) {
                        newSelectedEquipment[selectedEquipmentKey]!.values.push(
                          0,
                        );
                      } else {
                        newSelectedEquipment[selectedEquipmentKey]!.values = [
                          0,
                        ];
                      }

                      dispatch(
                        updateClientLocal({
                          user_id: userId,
                          equipment_detailed: newSelectedEquipment,
                        }),
                      );
                    }}
                    sx={{ mb: 1 }}
                  >
                    Add a weight
                  </Button>
                </>
              )}
              <TextField
                label="Notes"
                variant="outlined"
                size="small"
                fullWidth
                multiline={true}
                defaultValue={equipment[selectedEquipmentKey]?.notes}
                key={equipment[selectedEquipmentKey]?.notes}
                onBlur={(event) => {
                  const newSelectedEquipment = cloneDeep(equipment);

                  if (!event.target.value.replace(/\s/g, "").length) {
                    newSelectedEquipment[selectedEquipmentKey]!.notes = null;
                  } else {
                    newSelectedEquipment[selectedEquipmentKey]!.notes =
                      event.target.value;
                  }

                  dispatch(
                    updateClientLocal({
                      user_id: userId,
                      equipment_detailed: newSelectedEquipment,
                    }),
                  );
                }}
              />
            </Box>
          )}
        </Popover>
      )}
    </Box>
  );
}
