import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  resetPendingMessages,
  setChatView,
  setCheckInMode,
  toggleHideClientChat,
  toggleSelectingChats,
} from "src/slices/chatSlice";
import { ThreadsButton } from "./threads/ThreadsButton";

interface Props {
  condensed?: boolean;
}

export function ChatListHeader({ condensed }: Props) {
  const dispatch = useAppDispatch();
  const isChatFullscreen = useAppSelector(
    (state) => state.chat.isChatFullscreen,
  );
  const chatView = useAppSelector((state) => state.chat.view);
  const checkInMode = useAppSelector((state) => state.chat.checkInMode);
  const hideClientChat = useAppSelector((state) => state.chat.hideClientChat);
  const isSelectingChats = useAppSelector(
    (state) => state.chat.isSelectingChats,
  );
  const actionItemStatus = useAppSelector((state) => state.actionItems.status);
  const currentTab = useAppSelector((state) => state.chat.currentTab);

  return (
    <Box
      sx={{
        backgroundColor: (theme) => theme.palette.background.paper,
        display: "flex",
        alignItems: "center",
        width: "100%",
        px: 1,
        py: 1,
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {chatView === "threads" ? (
          <Grid container spacing={1} alignItems="center">
            <Grid size={"auto"}>
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(setChatView("default"));
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
              >
                <ArrowBackRoundedIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            <Grid size="grow">
              <Typography
                variant="h1"
                sx={{ fontWeight: "bold", textAlign: "start" }}
              >
                Unread threads
              </Typography>
            </Grid>
          </Grid>
        ) : chatView === "check_in" ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!condensed && (
              <IconButton
                size="small"
                sx={{ mr: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  if (checkInMode.type) {
                    dispatch(
                      setCheckInMode({
                        ...checkInMode,
                        type: undefined,
                        quickMessageEnabled: false,
                      }),
                    );

                    dispatch(resetPendingMessages());
                  } else if (checkInMode.cachedType) {
                    dispatch(
                      setCheckInMode({
                        ...checkInMode,
                        cachedType: undefined,
                        quickMessageEnabled: false,
                      }),
                    );
                  } else {
                    dispatch(setChatView("default"));
                  }
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                disabled={actionItemStatus !== "succeeded"}
              >
                <ArrowBackRoundedIcon fontSize="inherit" />
              </IconButton>
            )}
            {!condensed && <Typography variant="h1">Check in</Typography>}
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!condensed && <Typography variant="h1">Chat</Typography>}
            {!condensed && (
              <Button
                variant="text"
                size="small"
                sx={{ ml: 1 }}
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(setChatView("check_in"));
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                disabled={actionItemStatus !== "succeeded"}
              >
                Check in
              </Button>
            )}
          </Box>
        )}
      </Box>
      {!condensed && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {currentTab === "clients" && chatView === "default" && (
            <ThreadsButton />
          )}
          {currentTab === "clients" && chatView === "default" && (
            <Tooltip
              disableInteractive
              title={isSelectingChats ? "Cancel" : "Select chats"}
            >
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(toggleSelectingChats());
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
                sx={{ mr: 1 }}
              >
                {isSelectingChats ? (
                  <CancelRoundedIcon fontSize="inherit" />
                ) : (
                  <LibraryAddRoundedIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          )}
          {!isChatFullscreen && (
            <Tooltip
              disableInteractive
              title={hideClientChat ? "Show chat 'c'" : "Hide chat 'c'"}
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(toggleHideClientChat());
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onTouchStart={(event) => {
                  event.stopPropagation();
                }}
                size="small"
              >
                {hideClientChat ? (
                  <ChatBubbleRoundedIcon fontSize="inherit" />
                ) : (
                  <MenuOpenRoundedIcon fontSize="inherit" />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
      {condensed && (
        <Tooltip
          disableInteractive
          placement="right"
          title={hideClientChat ? "Show chat 'c'" : "Hide chat 'c'"}
        >
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();

              dispatch(toggleHideClientChat());
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          >
            {hideClientChat ? (
              <ChatBubbleRoundedIcon />
            ) : (
              <MenuOpenRoundedIcon />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
